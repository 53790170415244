/**
 * Created by ghofer on 10.11.15.
 */
$(document).ready(function () {
    // counterup.js
    $('.js-countup').counterUp({
        delay: 20,
        time: 1000,
    });

    // slickslider
    $('body:not(.tmpl-landingpage) .m-cite').slick({
        autoplay: false,
        autopaylspeed: 5000,
        arrows: true,
        speed: 900,
    });

    // scroll to top
    $('#toTop').click(function () {
        $('html, body').animate({ scrollTop: 0 }, 600);
        return false;
    });

    // slicknav
    $('#navbar').slicknav({
        label: '',
        allowParentLinks: true,
        nestedParentLinks: false,
        prependTo: 'body > nav',
        closedSymbol: '<i class="fa fa-angle-right fa-2"></i>',
        openedSymbol: '<i class="fa fa-angle-down fa-2"></i>',
        beforeOpen: function () {
            $('body').addClass('noscroll');
        },
        afterClose: function () {
            $('body').removeClass('noscroll');
        },
    });

    // add hr after 3rd article on startpage
    $('.m-news-list_default:not(.m-news-list_default__education) .m-news-list__articles article:nth-of-type(3)').after(
        '<hr class="m-news-borderTop">',
    );

    // news detail latest sidebar listing
    if ($('.m-news-detail').length > 0) {
        $('.sidebar').prepend('<div id="js-loaded-sidebar-news"></div>');
        $('#js-loaded-sidebar-news').fadeOut();
        $('.m-news-detail').each(function () {
            var url = $(this).data('latestnewssidebar-url');
            if (url) {
                $('#js-loaded-sidebar-news').load(url);
            }
        });
        $('#js-loaded-sidebar-news').fadeIn(2000);
    }
});

/*  global functions */
function getWidth() {
    if (self.innerWidth) return self.innerWidth;
    else if (document.documentElement && document.documentElement.clientHeight)
        return document.documentElement.clientWidth;
    else if (document.body) return document.body.clientWidth;
    return 0;
}

function getHeight() {
    if (self.innerHeight) return self.innerHeight;
    else if (document.documentElement && document.documentElement.clientHeight)
        return document.documentElement.clientHeight;
    else if (document.body) return document.body.clientHeight;
    return 0;
}
